import banner from '../assets/banner-right.png';
import topBg from '../assets/banner.svg';
import digitayoLogo from '../assets/digitayo-logo.svg';

const LMS_URL = process.env.REACT_APP_LMS_URL;

const TopInfo = () => {
  return (
    <div
      className="bg-cover bg-center min-h-screen snap-start lg:snap-center relative"
      style={{ backgroundImage: `url(${topBg})` }}
    >
      <img src={digitayoLogo} alt="Digitayo Logo" className="absolute w-auto pl-8 pt-8" />
      <div className="max-w-[1440px] mx-auto w-full">
        <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen h-100 max-w-[1140px] w-full mx-auto px-7 md:px-14 py-28 pt-36 lg:py-16">
          <div className="lg:w-1/2 text-left" data-aos="fade-right">
            <h1 className="bg-gradient-to-r from-digitayoOrange1 to-digitayoPurple1 bg-clip-text text-transparent text-[47px] leading-[130%] font-bold mb-5 lg:pr-20">
              Ready na ba maging digital ang iyong negosyo?
            </h1>
            <p className="text-digitayoBlack2 text-lg mb-8 lg:pr-44">
              In a digital world, kailangan digital-ready na rin ang negosyo mo. Let's start our digital journey together with DigiTayo!
            </p>
            <div className="flex w-full gap-3">
              <a href={`${LMS_URL}/signup`} target='_blank' rel='noreferrer'>
                <button
                  className="text-lg text-white rounded-[10px] h-[56px] w-[163px] leading-[50px] px-3 font-bold cursor-pointer bg-digitayoOrange1"
                  type="button"
                  onClick={() => {}}
                >
                  Register Now
                </button>
              </a>
              <a href={`${LMS_URL}/login`} target='_blank' rel='noreferrer'>
                <button
                  className="bg-transparent text-lg text-center border-2 h-[56px] w-[107px] rounded-[10px] font-bold cursor-pointer text-digitayoOrange1 border-digitayoOrange1"
                  type="button"
                  onClick={() => {}}
                >
                  Sign In
                </button>
              </a>
            </div>
          </div>
          <div className="lg:w-1/2 mt-14 lg:mt-0 flex justify-center">
            <img
              src={banner}
              alt="DigiTayo Mobile App"
              className="w-full lg:w-[797px] max-w-[797px] lg:-mr-[270px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopInfo;
