import { FaArrowRight } from 'react-icons/fa';

import digitayoLogoWhite from '../assets/digitayo-logo-white.svg';
import fbIcon from '../assets/fb.svg';
import igIcon from '../assets/ig.svg';
import youtubeIcon from '../assets/youtube.svg';
import emailIcon from '../assets/email-icon.svg';
import usaidWhite from '../assets/usaid-white.svg';

const LMS_URL = process.env.REACT_APP_LMS_URL;

const Footer = () => {
  return (
    <div className="bg-digitayoBlack4 snap-start lg:snap-center">
      <div className="max-w-[1440px] mx-auto w-full">
        <div className="flex flex-col lg:flex-row max-w-[1140px] w-full mx-auto px-7 md:px-14 py-28 lg:py-28">
          <div className="lg:w-1/2 text-left lg:pr-20">
            <img src={digitayoLogoWhite} alt="Digitayo Logo" className="w-auto mb-8" />
            <h2 className="text-digitayoWhite text-[32px] leading-[130%] font-bold mb-8">
              Start your journey towards digital success
            </h2>
            <div className="flex w-full gap-3">
              <a href={`${LMS_URL}/signup`} target='_blank' rel='noreferrer'>
                <button
                  className="text-lg text-white rounded-[10px] h-[56px] w-[163px] leading-[50px] px-3 font-bold cursor-pointer bg-digitayoOrange1"
                  type="button"
                  onClick={() => {}}
                >
                  Register Now
                </button>
              </a>
              <a href={`${LMS_URL}/login`} target='_blank' rel='noreferrer'>
                <button
                  className="bg-transparent text-lg text-center border-2 h-[56px] w-[107px] rounded-[10px] font-bold cursor-pointer text-digitayoOrange1 border-digitayoOrange1"
                  type="button"
                  onClick={() => {}}
                >
                  Sign In
                </button>
              </a>
            </div>
          </div>
          <div className="lg:w-1/2 mt-20 lg:mt-0 flex flex-col items-start lg:pl-28">
            <h2 className="text-digitayoWhite text-[32px] leading-[130%] font-bold mb-5">
              Get in touch
            </h2>
            <div className="flex flex-row items-center gap-3 text-lg text-digitayoWhite">
              <div>
                <img src={emailIcon} alt="icon" width="25" />
              </div>
              <a href='mailto:digitayoph.project@gmail.com' target='_blank' rel='noreferrer'>digitayoph.project@gmail.com</a>
            </div>
            <div className="flex items-center justify-center gap-4 mt-7">
              <a href="#" target="_blank" rel='noreferrer'>
                <img src={fbIcon} alt="Facebook" />
              </a>
              <a href="#" target="_blank" rel='noreferrer'>
                <img src={igIcon} alt="Instagram" />
              </a>
              <a href="#" target="_blank" rel='noreferrer'>
                <img src={youtubeIcon} alt="Youtube" />
              </a>
            </div>
            <img src={usaidWhite} alt="USAID" width="124" className='mt-9' />
            <p className="text-[8.5px] text-digitayoWhite mt-4 xl:-mr-[57px]">
              DigiTayo was developed with the support of USAID Philippines through its Strengthening Private Enterprise for the Digital Economy (SPEED) Activity as part of its capacity-building initiative for SMEs. 
              The platform offers a suite of digital readiness tools, personalized training, and mentorship to boost digital adoption, fostering economic growth and digital inclusivity.
            </p>
            <a href='https://digitayoph-privacy-policy.webflow.io/' target='_blank' rel='noreferrer' className='flex items-center gap-1 text-digitayoOrange1 text-base mt-[26px]'>
              <span>
                View Privacy Policy
              </span>
              <FaArrowRight size={15} className='text-digitayoOrange1' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
